<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">Loading...</span>
</div>

<div *ngIf="!loading && exibir">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">Pagamento por Pix</legend>
    <div>
      <p class="small mt-2">Clique abaixo para gerar o QrCode para pagamento</p>
      <button type="button" (click)="exibirQrCode()" class="botao_pix btn btn-secondary btn-sm">
        <i class="bi bi-qr-code-scan" aria-hidden="true">&nbsp;</i> Gerar QR Code
      </button>
    </div>
  </fieldset>
</div>

<div *ngIf="aguardandoAprovacaoReducao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">Pagamento</legend>
    <ul class="mb-0">
      <li class="text-danger">Aguardando a decisão do RH sobre seu pedido de redução de taxa</li>
    </ul>
  </fieldset>
</div>

<div *ngIf="problemaNoPix">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">Pagamento</legend>
    <ul class="mb-0">
      <li class="text-danger">Seu pix não está disponível, por favor entre em contato com a unidade.</li>
    </ul>
  </fieldset>
</div>
