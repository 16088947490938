import { Component, OnInit, Input } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'
import { BoletoModalService } from '../boleto-preview-modal/services/boleto-modal.service'

@Component({
  selector: 'app-pagamento-boleto',
  templateUrl: './pagamento-boleto.component.html',
  styleUrls: ['./pagamento-boleto.component.css'],
})
export class PagamentoBoletoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false
  problemaNoBoleto: boolean = false

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private boletoModalService: BoletoModalService
  ) {}

  ngOnInit(): void {
    if (this.inscricao?.periodoInscricao && this.inscricao?.boleto == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      } else if (
        this.inscricao?.periodoInscricao &&
        !this.inscricao?.pago &&
        !this.aguardandoAprovacaoReducao &&
        this.inscricao.boletoSolicitado
      ) {
        this.exibir = true
      } else if (!this.inscricao?.pago) {
        this.problemaNoBoleto = true
      }
    }
  }

  imprimir(): void {
    if (this.inscricao) {
      this.loading = true
      let tipo = 'BOLETO'
      this.inscricaoService.boleto(this.inscricao.id, tipo).subscribe(boleto => {
        if (boleto === null) {
          this.toastService.showErrorToast(
            'Atenção',
            'Seu boleto não está disponível, por favor entre em contato com a unidade.'
          )
        } else if (boleto.message == 'Ok') {
          this.boletoModalService.showPreview(boleto.url, `Boleto - inscrição #${this.inscricao?.id}`)
        } else {
          this.toastService.showErrorToast('Atenção', boleto.message)
        }
        this.loading = false
      })
    }
  }
}
