<ng-container *ngIf="exibir">
  <div class="simple-modal">
    <div class="simple-modal-dialog modal-lg">
      <div class="simple-modal-content">
        <div class="simple-modal-header">
          <h5 class="simple-modal-title">
            {{ pixPreview?.title }}
          </h5>
        </div>
        <div class="simple-modal-body">
          <embed [src]="trustedUrl" type="application/pdf" width="220px" height="220px" />
          <a (click)="copiaECola()" class="btn btn-primary copia">Clique aqui para copiar o código</a>
          <legend *ngIf="exibeMensagem">Texto Copiado para a área de transferência</legend>
        </div>
      </div>
    </div>
  </div>
  <div (click)="toggle()" class="overlay"></div>
</ng-container>
