import { Component, OnInit, Input } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'
import { PixModalService } from '../pix-preview-modal/services/pix-modal.service'
@Component({
  selector: 'app-pagamento-pix',
  templateUrl: './pagamento-pix.component.html',
  styleUrls: ['./pagamento-pix.component.css'],
})
export class PagamentoPixComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false
  problemaNoPix: boolean = false
  possuiCodigoPix: boolean = false

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private pixModalService: PixModalService
  ) {}

  ngOnInit(): void {
    if (this.inscricao?.periodoInscricao && this.inscricao?.pix == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      } else if (
        this.inscricao?.periodoInscricao &&
        !this.inscricao?.pago &&
        !this.aguardandoAprovacaoReducao &&
        this.inscricao.pixSolicitado
      ) {
        this.exibir = true
      } else if (!this.inscricao?.pago) {
        this.problemaNoPix = true
      }
    }
  }

  exibirQrCode(): void {
    if (this.inscricao) {
      this.loading = true
      let tipo = 'PIX'
      this.inscricaoService.pix(this.inscricao.id, tipo).subscribe(pix => {
        if (pix === null) {
          this.toastService.showErrorToast(
            'Atenção',
            'Seu Pix não está disponível, por favor entre em contato com a unidade.'
          )
        } else if (pix.message == 'Ok') {
          this.pixModalService.showPreview(pix.url, `Pix - inscrição #${this.inscricao?.id}`, pix.text)
        } else {
          this.toastService.showErrorToast('Atenção', `Houve um problema na emissão do PIX - ${pix.message}`)
        }
        this.loading = false
      })
    }
  }
}
